
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import { inboxSidebarRoutes } from '@/router/sidebarRoutes/inboxSidebarRoutes'
import type { BreadcrumbsLink } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    PageContent,
    PageContentSidebarList,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbsLink[]>,
    },
  },
  setup(props, context) {
    const hasContentWhiteSlot = !!context.slots['page-content-white']
    const hasAfterContentWhiteSlot = !!context.slots['after-content-white']
    return {
      inboxSidebarRoutes,
      hasAfterContentWhiteSlot,
      hasContentWhiteSlot,
    }
  },
})
