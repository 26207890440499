import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_content_sidebar_list = _resolveComponent("page-content-sidebar-list")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    breadcrumbs: _ctx.breadcrumbs,
    "bg-shade": "",
    "menu-title": "Messenger settings"
  }, _createSlots({
    "filter-bar-slot": _withCtx(() => [
      _createVNode(_component_page_content_sidebar_list, { items: _ctx.inboxSidebarRoutes }, null, 8, ["items"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 2
  }, [
    (_ctx.hasContentWhiteSlot)
      ? {
          name: "page-content-white",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "page-content-white")
          ])
        }
      : undefined,
    (_ctx.hasAfterContentWhiteSlot)
      ? {
          name: "after-content-white",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "after-content-white")
          ])
        }
      : undefined
  ]), 1032, ["breadcrumbs"]))
}