import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    selected: _ctx.selected,
    "search-placeholder": "Search widgets"
  }, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        icon: "add",
        text: "New widget",
        onClick: _ctx.openNewWidgetModal
      }, null, 8, ["onClick"])
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        icon: "block",
        text: "Deactivate",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeactivateWidgetsModal(_ctx.selected)))
      }),
      _createVNode(_component_top_filter_button, {
        icon: "refresh",
        text: "Activate",
        onClick: _ctx.openActivateWidgetModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        icon: "delete",
        text: "Delete",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDeleteWidgetsModal(_ctx.selected)))
      })
    ]),
    _: 1
  }, 8, ["selected"]))
}