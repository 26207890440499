
import { defineComponent } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import CodeTag from '@/components/shared/templates/CodeTag.vue'

export default defineComponent({
  components: {
    TmExpansion,
    CodeTag,
  },
})
