
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    isActive: {
      type: Boolean,
    },
  },
  setup() {
    const {
      openEditWidgetModal,
      openDeactivateWidgetModal,
      openDuplicateWidgetModal,
      openDeleteWidgetModal,
      openTestWidgetPage,
      openActivateWidgetModal,
    } = useChatsSettings()

    return {
      openEditWidgetModal,
      openDeactivateWidgetModal,
      openDuplicateWidgetModal,
      openDeleteWidgetModal,
      openTestWidgetPage,
      openActivateWidgetModal,
    }
  },
})
