
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TopFilter,
    TopFilterButton,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const { openNewWidgetModal, openDeactivateWidgetsModal, openDeleteWidgetsModal, openActivateWidgetModal } = useChatsSettings()

    return {
      openNewWidgetModal,
      openDeactivateWidgetsModal,
      openDeleteWidgetsModal,
      openActivateWidgetModal,
    }
  },
})
