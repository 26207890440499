
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmTextCopy from '@/components/shared/TmTextCopy.vue'
import WidgetsDropdown from '@/components/pages/chats/settings/channels/web-widgets/WidgetsDropdown.vue'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    WidgetsDropdown,
    TmTextCopy,
    TmEllipsis,
    TmButton,
    TmStatus,
    TmTable,
    TmBadge,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const { openEditWidgetModal } = useChatsSettings()
    return {
      openEditWidgetModal,
    }
  },
})
